import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment, type PropsWithChildren } from 'react';
import { P, match } from 'ts-pattern';
import { formatUnits } from 'viem';

import { GetRecommendationById } from '@endaoment-frontend/api';
import { convertEntityLabelToDonationRecipient } from '@endaoment-frontend/donation-wizard';
import type { SearchParamsUsedByWizards } from '@endaoment-frontend/routes';
import { assembleSearchParamsForWizards } from '@endaoment-frontend/routes';
import type { RecommendationDetails, RecommendationListing } from '@endaoment-frontend/types';
import { CollaborateIcon, PopupIcon, StockIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card, Pill } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import { FundPill, OrgPill, PortfolioPill } from './ActivitySection/ActivityPills';
import styles from './PendingRecommendations.module.scss';

type Location = 'dashboard' | 'fund';
type LocationProp = { location: Location };

export const PendingRecommendations = ({
  recommendations,
  location = 'dashboard',
  isExecutable = () => false,
}: {
  recommendations: Array<RecommendationListing>;
  location?: Location;
  isExecutable?: (r: RecommendationListing) => boolean;
}) => {
  return (
    <Card
      className={clsx(
        styles['pending-recommendations'],
        styles['pending-recommendations--manager'],
        location === 'fund' && styles['pending-recommendations--fund'],
      )}>
      <h2 className={styles.title}>Pending Recommendations</h2>

      {recommendations.map(recommendation => (
        <Fragment key={recommendation.id}>
          <hr />
          <BaseRecommendation
            key={recommendation.id}
            recommendation={recommendation}
            enableExecution={isExecutable(recommendation)}>
            {match(recommendation)
              .with({ type: 'grant' }, recommendation => (
                <GrantRecommendation recommendation={recommendation} location={location} />
              ))
              .with({ type: 'trade' }, recommendation => (
                <PortfolioTradeRecommendation recommendation={recommendation} location={location} />
              ))
              .with({ type: P.union('donation', 'nec_crypto_donation') }, recommendation => (
                <DonationRecommendation recommendation={recommendation} location={location} />
              ))
              .with({ type: 'stock_donation_pledge' }, recommendation => (
                <StockDonationPledgeRecommendation recommendation={recommendation} location={location} />
              ))
              .exhaustive()}
          </BaseRecommendation>
        </Fragment>
      ))}
    </Card>
  );
};

// Map recommendation to wizard args
export const convertRecommendationToSearchParams = async (
  currentPath: string,
  recommendation: RecommendationDetails,
): Promise<string> => {
  const wizardArgs = await match(recommendation)
    .returnType<Promise<SearchParamsUsedByWizards> | SearchParamsUsedByWizards>()
    .with({ type: 'donation_details' }, recommendation => ({
      isDonationWizardOpen: true,
      recommendationId: recommendation.id,
      dwMode: 'erc-donation',
      dwAmount: recommendation.inputAmount,
      dwRecipient: { type: 'fund', id: recommendation.collaboratorFund.id },
      ercToken: recommendation.token,
    }))
    .with({ type: 'grant_details' }, async recommendation => ({
      isDonationWizardOpen: true,
      recommendationId: recommendation.id,
      dwMode: 'grant',
      dwRecipient: await convertEntityLabelToDonationRecipient(recommendation.destinationEntity),
      dwAmount: recommendation.amountUsdc,
      grantOriginId: recommendation.collaboratorFund.id,
      grantInstructions: {
        purpose: recommendation.purpose ?? '',
        recommender: recommendation.recommender ?? '',
        specialInstructions: recommendation.specialInstructions ?? '',
        shareMyEmail: true,
      },
    }))
    .with({ type: 'stock_donation_pledge_details' }, recommendation => ({
      isDonationWizardOpen: true,
      recommendationId: recommendation.id,
      dwMode: 'brokerage-donation',
      dwRecipient: { type: 'fund', id: recommendation.collaboratorFund.id },
      brokerageTicker: recommendation.stock,
      brokerageShares: recommendation.shares,
      brokerageLots: recommendation.lots.map(lot => ({
        ...lot,
        lotId: lot.lotId ?? undefined,
        employeeStockPlan: lot.employeeStockPlan ?? undefined,
      })),
      brokerageBroker: {
        brokerage: {
          label: recommendation.brokerData.customBrokerName ?? recommendation.brokerData.brokerName,
          name: recommendation.brokerData.brokerName,
        },
        brokerageAccountNumber: recommendation.brokerData.brokerageAccountNumber,
        brokerageContactName: recommendation.brokerData.brokerContactName,
        brokerageEmail: recommendation.brokerData.brokerEmail,
        brokeragePhone: recommendation.brokerData.brokerPhone,
      },
    }))
    .with({ type: 'crypto_donation_pledge_details' }, recommendation => ({
      isDonationWizardOpen: true,
      recommendationId: recommendation.id,
      dwMode: 'otc-donation',
      dwRecipient: { type: 'fund', id: recommendation.collaboratorFund.id },
      dwAmount: recommendation.inputAmount,
      otcTokenId: recommendation.token.id,
    }))
    .with({ type: 'trade_details' }, recommendation => ({
      isPortfolioWizardOpen: true,
      recommendationId: recommendation.id,
      portfolioWizardAmount: recommendation.inputAmountUsdc,
      portfolioWizardFundId: recommendation.collaboratorFund.id,
      portfolioWizardIsDeposit: recommendation.tradeType === 'Buy',
      portfolioWizardPortfolioId: recommendation.portfolio.id,
    }))
    .exhaustive();

  return assembleSearchParamsForWizards(currentPath, wizardArgs);
};

const BaseRecommendation = ({
  recommendation,
  enableExecution,
  children,
}: PropsWithChildren<{ recommendation: RecommendationListing; enableExecution: boolean }>) => {
  // const queryClient = useQueryClient();
  // const { mutateAsync: dismissRecommendation } = useMutation({
  //   mutationFn: (id: UUID) => DismissRecommendation.execute(id),
  //   onSuccess: () => {
  //     GetRecommendationsForFund.invalidateQuery(queryClient, [recommendation.collaboratorFund.id]);
  //     GetRecommendationsMadeByMe.invalidateQuery(queryClient, []);
  //     GetRecommendationsMadeForMe.invalidateQuery(queryClient, []);
  //   },
  // });

  const router = useRouter();
  const handleExecute = async () => {
    const recommendationDetails = await GetRecommendationById.fetchFromDefaultClient([recommendation.id]);
    const recommendationLinkPath = await convertRecommendationToSearchParams(router.asPath, recommendationDetails);

    router.push(recommendationLinkPath, undefined, { shallow: true });
  };

  const { firstName: collaboratorFirstName, lastName: collaboratorLastName } = recommendation.collaborator;

  return (
    <div className={styles.recommendation} data-testid='recommendation-list-item'>
      <div className={styles.content}>
        <Pill size='tiny' variation='orange'>
          {collaboratorFirstName} {collaboratorLastName}
          <CollaborateIcon />
        </Pill>
        <b className={styles['verb--recommend']}>recommends</b> {children}
      </div>
      <div className={styles.controls}>
        {!!enableExecution && (
          <Button size='small' className={styles['execute-button']} onClick={handleExecute}>
            Execute <PopupIcon color='currentColor' />
          </Button>
        )}
        {/* TODO: Enable when dismissal logic exists */}
        {/* <Button
          onClick={() => dismissRecommendation(recommendation.id)}
          size='small'
          float={false}
          minimal
          className={styles['close-button']}>
          <CloseIcon />
        </Button> */}
      </div>
    </div>
  );
};

const GrantRecommendation = ({
  recommendation,
  location,
}: LocationProp & { recommendation: Extract<RecommendationListing, { type: 'grant' }> }) => {
  const { amountUsdc, collaboratorFund, destinationEntity } = recommendation;
  const { id: fundId, name: fundName } = collaboratorFund;

  return (
    <>
      {location === 'dashboard' ? (
        <>
          <FundPill id={fundId} name={fundName} />
          &nbsp;<b className={styles['verb--grant']}>grant</b>
        </>
      ) : (
        <b className={styles['verb--grant']}>granting</b>
      )}
      <span>{formatCurrency(formatUsdc(amountUsdc))}</span> to <OrgPill org={destinationEntity} />
    </>
  );
};

const PortfolioTradeRecommendation = ({
  recommendation,
  location,
}: LocationProp & { recommendation: Extract<RecommendationListing, { type: 'trade' }> }) => {
  const { inputAmountUsdc, portfolio, tradeType, collaboratorFund } = recommendation;
  const { id: fundId, name: fundName } = collaboratorFund;

  return (
    <>
      {location === 'dashboard' ? (
        <>
          <FundPill id={fundId} name={fundName} />
          <b className={tradeType === 'Buy' ? styles['verb--buy'] : styles['verb--sell']}>
            {tradeType === 'Buy' ? 'add' : 'sell'}
          </b>
        </>
      ) : tradeType === 'Buy' ? (
        <b className={styles['verb--buy']}>adding</b>
      ) : (
        <b className={styles['verb--sell']}>selling</b>
      )}
      <span>{formatCurrency(formatUsdc(inputAmountUsdc))}</span> {tradeType === 'Buy' ? 'to' : 'from'} the
      <PortfolioPill portfolio={portfolio} /> portfolio
    </>
  );
};

const DonationRecommendation = ({
  recommendation,
  location,
}: LocationProp & { recommendation: Extract<RecommendationListing, { type: 'donation' | 'nec_crypto_donation' }> }) => {
  const { inputAmount, token, collaboratorFund } = recommendation;
  const { id: fundId, name: fundName } = collaboratorFund;

  return (
    <>
      <b className={styles['verb--donate']}>donating</b>
      <span>
        {formatUnits(inputAmount, token.decimals)} {token.symbol}
      </span>
      <Image src={token.logoUrl} alt='' width={20} height={20} />
      {location === 'dashboard' && (
        <>
          to <FundPill id={fundId} name={fundName} />
        </>
      )}
    </>
  );
};

const StockDonationPledgeRecommendation = ({
  recommendation,
  location,
}: LocationProp & { recommendation: Extract<RecommendationListing, { type: 'stock_donation_pledge' }> }) => {
  const { stock, shares, collaboratorFund } = recommendation;
  const { id: fundId, name: fundName } = collaboratorFund;

  return (
    <>
      <b className={styles['verb--donate']}>donating</b>
      <span>
        {shares} {stock.ticker}
      </span>
      via
      <Pill size='tiny' variation='green'>
        <StockIcon /> Brokerage
      </Pill>
      {location === 'dashboard' && (
        <>
          to <FundPill id={fundId} name={fundName} />
        </>
      )}
    </>
  );
};
