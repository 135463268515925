import { skipToken } from '@tanstack/react-query';
import { z } from 'zod';

import { WhoAmI } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { RequestHandler } from '@endaoment-frontend/data-fetching';
import { routes } from '@endaoment-frontend/routes';
import { LogoIcon } from '@endaoment-frontend/ui/icons';
import { Card, CopyTooltip } from '@endaoment-frontend/ui/shared';

import styles from './ReferralCodeCard.module.scss';

const GetReferralCode = new RequestHandler('GetReferralCode', fetch => async (userId: string) => {
  const res = await fetch(`/v1/referral/${userId}/code`);
  const parsed = z
    .object({
      code: z.string(),
    })
    .parse(res);
  return parsed.code;
});

export const ReferralCodeCard = () => {
  const { isSignedIn } = useAuth();
  const { data: authToken } = WhoAmI.useQuery([], {
    enabled: isSignedIn,
  });
  const { data: referralCode, isPending: isPendingReferralCode } = GetReferralCode.useQuery(
    authToken ? [authToken.userId] : skipToken,
    {
      enabled: !!authToken,
    },
  );

  if (!isSignedIn || isPendingReferralCode || !referralCode) return <></>;

  const link = routes.app.refer({ code: referralCode, useFullUrl: true });

  return (
    <Card className={styles['card']}>
      <h6>Invite your friends + family to Endaoment</h6>
      <div className={styles['link-text']}>
        <p>{link}</p>
        <CopyTooltip displayText='Copy Link' copyText={link} icon='link' buttonClassName={styles['tooltip-button']} />
      </div>
      <LogoIcon className={styles['card-logo']} />
    </Card>
  );
};
